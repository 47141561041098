// src/contexts/AuthContext.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export interface IUser {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    courses: ICourse[];
}

export interface ICourse {
    course_id: number;
    name: string;
    payment_status: string;
    paid_amount: number;
    total_amount: number;
    payment_percentage: number;
    remaining_balance: number;
    currency: string;
    scheduled_payments: IScheduledPayment[];
    sections: ISection[];

    // Добавляем поле payment_id
    payment_id: number;
}

export interface IScheduledPayment {
    id: number;
    amount: number;
    scheduled_date: string;
    status: string;
}

export interface ISection {
    section_id: number;
    title: string;
    description: string;
    is_available: boolean;
    available_from: string | null;
    available_in: number | null;
    unavailable_reason: string | null;
    videos: IVideo[];
}

export interface IVideo {
    video_id: number;
    title: string;
    description: string;
    kinescope_id: string;
    kinescope_embed_url?: string;
    is_available: boolean;
    available_from: string | null;
    available_in: number | null;
    unavailable_reason: string | null;
}


interface AuthContextType {
    currentUser: IUser | null;
    setCurrentUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    logout: () => void;
    loading: boolean;
    fetchCurrentUser: () => Promise<void>;
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(null);

    const logout = () => {
        localStorage.removeItem('access_token');
        setToken(null);
        setCurrentUser(null);
    };

    const fetchCurrentUser = async () => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            setToken(storedToken);
            try {
                const response = await fetch('https://amazonfreedom.ru/api/me', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${storedToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setCurrentUser(data);
                } else {
                    localStorage.removeItem('access_token');
                    setToken(null);
                    setCurrentUser(null);
                }
            } catch (error) {
                console.error('Ошибка при получении данных пользователя', error);
                localStorage.removeItem('access_token');
                setToken(null);
                setCurrentUser(null);
            }
        } else {
            setCurrentUser(null);
            setToken(null);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCurrentUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, logout, loading, fetchCurrentUser, token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth должен использоваться внутри AuthProvider');
    }
    return context;
};
