// src/pages/Dashboard.tsx

import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CourseItem from '../components/Dashboard/CourseItem';

const Dashboard: React.FC = () => {
    const { currentUser, logout, loading } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    if (loading) {
        return <LoadingContainer>Загрузка...</LoadingContainer>;
    }

    if (!currentUser) {
        return <ErrorContainer>Пользователь не найден</ErrorContainer>;
    }

    return (
        <DashboardContainer>
            <Header>
                <Title>Добро пожаловать, {currentUser.first_name}!</Title>
                <LogoutButton onClick={handleLogout}>Выйти</LogoutButton>
            </Header>
            <Content>
                <SectionTitle>Мои курсы</SectionTitle>
                {currentUser.courses.length > 0 ? (
                    <CourseList>
                        {currentUser.courses.map((course) => (
                            <CourseItem key={course.course_id} course={course} />
                        ))}
                    </CourseList>
                ) : (
                    <NoCoursesMessage>У вас пока нет доступных курсов.</NoCoursesMessage>
                )}
            </Content>
        </DashboardContainer>
    );
};

const DashboardContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Jost', sans-serif;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.h1`
    color: #001B4F;
    font-size: 32px;
`;

const LogoutButton = styled.button`
    background-color: #FF9900;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
`;

const Content = styled.div`
    margin-top: 40px;
`;

const SectionTitle = styled.h2`
    color: #FF9900;
    font-size: 28px;
    margin-bottom: 20px;
`;

const CourseList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const LoadingContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
`;

const ErrorContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
    color: red;
`;

const NoCoursesMessage = styled.p`
    font-size: 18px;
    color: #333;
`;

export default Dashboard;
