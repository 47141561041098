// src/pages/VideoPlayer.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';
import DOMPurify from 'dompurify';
import KinescopePlayer from '@kinescope/react-kinescope-player';

const VideoPlayer: React.FC = () => {
    const { video_id } = useParams<{ video_id: string }>();
    const navigate = useNavigate();
    const { currentUser, loading: authLoading } = useAuth();
    const [video, setVideo] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token && video_id) {
            setLoading(true);
            fetch(`https://amazonfreedom.ru/api/video/${video_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {
                        const data = await response.json();
                        setVideo(data);
                        setLoading(false);
                    } else {
                        const errorData = await response.json();
                        setError(errorData.message || 'Ошибка при загрузке видео.');
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.error('Ошибка при получении видео', err);
                    setError('Ошибка при загрузке видео.');
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [video_id]);

    if (authLoading || loading) {
        return <LoadingContainer>Загрузка...</LoadingContainer>;
    }

    if (error) {
        return <ErrorContainer>{error}</ErrorContainer>;
    }

    if (!video || !currentUser) {
        return <ErrorContainer>Видео не найдено.</ErrorContainer>;
    }

    const sanitizedDescription = DOMPurify.sanitize(video.description || '');

    return (
        <VideoPageContainer>
            <Header>
                <BackButton onClick={() => navigate(-1)}>
                    <ArrowLeftIcon />
                    Назад
                </BackButton>
                <VideoTitle>{video.title}</VideoTitle>
            </Header>
            <VideoContent>
                <PlayerWrapper>
                    <KinescopePlayer
                        videoId={video.kinescope_id}
                        width="100%"
                        height={500}
                        controls={true}
                        muted={false}
                        autoPlay={false}
                        playsInline={true}
                        preload="metadata"
                        drmAuthToken={video.drm_token} // Используем токен, полученный с бэкенда
                    />
                </PlayerWrapper>
                {video.description && (
                    <VideoDescription
                        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    ></VideoDescription>
                )}
            </VideoContent>
        </VideoPageContainer>
    );
};

// Стили

const VideoPageContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Jost', sans-serif;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #001B4F;
    font-size: 16px;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
        text-decoration: underline;
    }
`;

const ArrowLeftIcon = styled(ArrowLeft)`
    margin-right: 5px;
`;

const VideoTitle = styled.h1`
    color: #001B4F;
    font-size: 24px;
`;

const VideoContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const VideoDescription = styled.div`
    color: #333;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
    margin-top: 20px;

    a {
        color: #1a0dab;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
`;

const PlayerWrapper = styled.div`
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
`;

const LoadingContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
`;

const ErrorContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
    color: red;
`;

export default VideoPlayer;
