// src/components/Dashboard/CourseItem.tsx

import React, { useState } from 'react';
import styled from 'styled-components';
import SectionItem from './SectionItem';
import { ICourse } from '../../contexts/AuthContext';
import { useAuth } from '../../contexts/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51PTap92KbS5VetKef3ZFhoAhKU9A2HFIhTFKzJE3W97nzkL6pdPmZS72OGQYxJtJyC9rEBZeXF8btolc8La7w6Mm00ltNb1DeT');

interface CourseItemProps {
    course: ICourse;
}

// Интерфейс для привязанных карт
interface LinkedCard {
    id: string;
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
}

const CourseItem: React.FC<CourseItemProps> = ({ course }) => {
    const paymentProgress = course.payment_percentage;
    const { token, currentUser } = useAuth();

    const [paying, setPaying] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [repaymentCourse, setRepaymentCourse] = useState<ICourse | null>(null);
    const [repaymentScheduledPaymentId, setRepaymentScheduledPaymentId] = useState<number | null>(null);
    const [repaymentFullPay, setRepaymentFullPay] = useState(false);

    const [showScheduledPayments, setShowScheduledPayments] = useState(false);
    const [showAttempts, setShowAttempts] = useState(false);

    // Состояние для привязанных карт (только визуально)
    const [linkedCards, setLinkedCards] = useState<LinkedCard[]>([
        {
            id: 'card_1',
            brand: 'Visa',
            last4: '4242',
            expMonth: 12,
            expYear: 2024,
        },
        {
            id: 'card_2',
            brand: 'MasterCard',
            last4: '5555',
            expMonth: 6,
            expYear: 2025,
        },
    ]);

    const canPayFullRemaining = course.payment_status !== 'completed' && course.remaining_balance > 0;
    const attempts = (course as any).attempts || [];
    const scheduled_payments = course.scheduled_payments || [];

    const handlePayFullRemaining = async () => {
        if (!token) return;
        setPaying(true);
        setErrorMessage(null);
        try {
            const response = await fetch('/api/pay_full_remaining', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    payment_id: (course as any).payment_id
                })
            });
            const data = await response.json();
            if (response.ok) {
                if (data.confirmation && data.confirmation.confirmation_url) {
                    window.location.href = data.confirmation.confirmation_url;
                }
            } else {
                setErrorMessage(data.message || 'Ошибка при оплате остатка');
                if (data.message === 'fail_no_saved_method') {
                    setRepaymentCourse(course);
                    setRepaymentScheduledPaymentId(null);
                    setRepaymentFullPay(true);
                    setShowPurchaseModal(true);
                }

            }
        } catch (e: any) {
            setErrorMessage(e.message);
        } finally {
            setPaying(false);
        }
    };

    const handlePayInstallment = async (scheduled_payment_id: number) => {
        if (!token) return;
        setPaying(true);
        setErrorMessage(null);
        try {
            const response = await fetch('/api/pay_installment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    payment_id: (course as any).payment_id,
                    scheduled_payment_id: scheduled_payment_id
                })
            });
            const data = await response.json();
            if (response.ok) {
                if (data.confirmation && data.confirmation.confirmation_url) {
                    window.location.href = data.confirmation.confirmation_url;
                }
            } else {
                setErrorMessage(data.message || 'Ошибка при оплате платежа');
                if (data.message === 'fail_no_saved_method') {
                    setRepaymentCourse(course);
                    setRepaymentScheduledPaymentId(scheduled_payment_id);
                    setRepaymentFullPay(false);
                    setShowPurchaseModal(true);
                }

            }
        } catch (e: any) {
            setErrorMessage(e.message);
        } finally {
            setPaying(false);
        }
    };

    // Функция удаления карты (только визуально)
    const handleDeleteCard = (cardId: string) => {
        setLinkedCards(prevCards => prevCards.filter(card => card.id !== cardId));
    };

    return (
        <CourseContainer>
            <CourseHeader>
                <CourseTitle>{course.name}</CourseTitle>
                <PaymentStatus paid={course.payment_status === 'completed'}>
                    {course.payment_status === 'completed' ? 'Оплачено' : 'Оплата в процессе'}
                </PaymentStatus>
            </CourseHeader>

            {currentUser?.id === 13 && (
                <CardsSection>
                    <SectionHeader>Привязанные карты</SectionHeader>
                    {linkedCards.length === 0 ? (
                        <NoCardsText>Нет привязанных карт.</NoCardsText>
                    ) : (
                        linkedCards.map(card => (
                            <CardItem key={card.id}>
                                <CardInfo>
                                    <CardBrand>{card.brand}</CardBrand>
                                    <CardNumber>•••• {card.last4}</CardNumber>
                                    <CardExpiry>Срок действия: {card.expMonth}/{card.expYear}</CardExpiry>
                                </CardInfo>
                                <DeleteCardButton onClick={() => handleDeleteCard(card.id)}>
                                    Удалить
                                </DeleteCardButton>
                            </CardItem>
                        ))
                    )}
                </CardsSection>
            )}

            {course.payment_status !== 'completed' && (
                <PaymentInfo>
                    <ProgressBar percentage={paymentProgress} />
                    <PaymentInfoRow>
                        <ProgressContainer>
                            <ProgressText>{paymentProgress}% оплачено</ProgressText>
                        </ProgressContainer>
                        <RemainingBalance>
                            Остаток к оплате: {course.remaining_balance}{' '}
                            {course.currency.toUpperCase()}
                        </RemainingBalance>
                    </PaymentInfoRow>

                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

                    <ButtonsContainer>
                        {canPayFullRemaining && (
                            <PayButton onClick={handlePayFullRemaining} disabled={paying}>
                                {paying ? 'Оплата...' : `Оплатить остаток полностью (${course.remaining_balance} ${course.currency.toUpperCase()})`}
                            </PayButton>
                        )}
                    </ButtonsContainer>

                    {attempts.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <ToggleButton onClick={() => setShowAttempts(!showAttempts)}>
                                {showAttempts ? 'Скрыть попытки платежей' : 'Показать попытки платежей'}
                            </ToggleButton>
                            {showAttempts && (
                                <AttemptsContainer>
                                    <AttemptsTitle>Попытки платежей:</AttemptsTitle>
                                    {attempts.map((att: any, idx: number) => (
                                        <AttemptItem key={idx}>
                                            <span>{new Date(att.attempt_date).toLocaleString()} - Статус: {att.status}</span>
                                            {att.error_message && <span>Ошибка: {att.error_message}</span>}
                                            {att.status === 'failed' && (
                                                <PayButton onClick={() => {
                                                    setRepaymentCourse(course);
                                                    setRepaymentFullPay(false);
                                                    setRepaymentScheduledPaymentId(null);
                                                    setShowPurchaseModal(true);
                                                }}>
                                                    Оплатить заново
                                                </PayButton>
                                            )}
                                        </AttemptItem>
                                    ))}
                                </AttemptsContainer>
                            )}
                        </div>
                    )}

                    {scheduled_payments.length > 0 && (
                        <div style={{ marginTop: '20px' }}>
                            <ToggleButton onClick={() => setShowScheduledPayments(!showScheduledPayments)}>
                                {showScheduledPayments ? 'Скрыть запланированные платежи' : 'Показать запланированные платежи'}
                            </ToggleButton>
                            {showScheduledPayments && (
                                <ScheduledPaymentsWrapper>
                                    <SectionHeader>Запланированные платежи</SectionHeader>
                                    <SectionDescription>
                                        Здесь вы можете увидеть предстоящие платежи по рассрочке. Если какой-то платеж не прошел или вам нужно оплатить вручную, воспользуйтесь кнопкой "Оплатить сейчас".
                                    </SectionDescription>
                                    {scheduled_payments.map((sp) => {
                                        let statusText = '';
                                        let statusColor = '';
                                        let canPay = false;

                                        if (sp.status === 'scheduled') {
                                            statusText = 'Запланирован';
                                            statusColor = '#0056b3';
                                            canPay = true;
                                        } else if (sp.status === 'failed') {
                                            statusText = 'Платеж не прошел, пожалуйста, оплатите вручную, чтобы не потерять доступ';
                                            statusColor = '#D32F2F';
                                            canPay = true;
                                        } else if (sp.status === 'completed') {
                                            statusText = 'Оплачен';
                                            statusColor = '#4CAF50';
                                            canPay = false;
                                        } else {
                                            // Другие статусы при необходимости
                                            statusText = sp.status;
                                        }

                                        const dateString = new Date(sp.scheduled_date).toLocaleDateString();

                                        return (
                                            <ScheduledPaymentItem key={sp.scheduled_date}>
                                                <PaymentInfoBlock>
                                                    <PaymentDate>{dateString}</PaymentDate>
                                                    <PaymentAmount>{sp.amount} {course.currency.toUpperCase()}</PaymentAmount>
                                                    <PaymentStatusText style={{ color: statusColor }}>{statusText}</PaymentStatusText>
                                                </PaymentInfoBlock>
                                                {canPay && (
                                                    <PayButton onClick={() => handlePayInstallment(sp.id!)} disabled={paying}>
                                                        {paying ? 'Оплата...' : 'Оплатить сейчас'}
                                                    </PayButton>
                                                )}
                                            </ScheduledPaymentItem>
                                        );
                                    })}
                                </ScheduledPaymentsWrapper>
                            )}
                        </div>
                    )}
                </PaymentInfo>
            )}

            <SectionsContainer>
                {course.sections.map((section) => (
                    <SectionItem key={section.section_id} section={section} course={course} />
                ))}
            </SectionsContainer>

            {showPurchaseModal && repaymentCourse && currentUser && (
                <ModalOverlay onClick={() => { setShowPurchaseModal(false); }}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <ModalClose onClick={() => setShowPurchaseModal(false)}>&times;</ModalClose>
                        <Elements stripe={stripePromise}>
                            <RepaymentModal
                                course={repaymentCourse}
                                onClose={() => setShowPurchaseModal(false)}
                                repaymentScheduledPaymentId={repaymentScheduledPaymentId}
                                repaymentFullPay={repaymentFullPay}
                                currentUser={currentUser}
                            />
                        </Elements>
                    </ModalContent>
                </ModalOverlay>
            )}
        </CourseContainer>
    )
};

interface RepaymentModalProps {
    course: ICourse;
    onClose: () => void;
    repaymentScheduledPaymentId: number | null;
    repaymentFullPay: boolean;
    currentUser: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        phone?: string;
    };
}

const RepaymentModal: React.FC<RepaymentModalProps> = ({ course, onClose, repaymentScheduledPaymentId, repaymentFullPay, currentUser }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const currency = course.currency.toLowerCase();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        let endpoint = repaymentFullPay ? '/api/pay_full_remaining' : '/api/pay_installment';
        const requestBody: any = {
            payment_id: (course as any).payment_id
        };
        if (!repaymentFullPay && repaymentScheduledPaymentId !== null) {
            requestBody.scheduled_payment_id = repaymentScheduledPaymentId;
        }

        if (currency === 'usd') {
            if (!stripe || !elements) {
                setError('Stripe не инициализирован');
                setLoading(false);
                return;
            }

            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                setError('Не найден элемент карты');
                setLoading(false);
                return;
            }

            try {
                const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

                if (paymentError) {
                    setError(paymentError.message || 'Произошла ошибка');
                    setLoading(false);
                    return;
                }

                requestBody.paymentMethodId = paymentMethod.id;

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                    body: JSON.stringify(requestBody)
                });

                const data = await response.json();
                if (response.ok) {
                    window.location.href = '/payment-success';
                    onClose();
                } else {
                    setError(data.message || 'Ошибка при повторной оплате');
                    if (data.confirmation && data.confirmation.confirmation_url) {
                        window.location.href = data.confirmation.confirmation_url;
                    }
                }
            } catch (error: any) {
                console.error(error);
                setError('Ошибка во время повторной оплаты');
            }

        } else {
            // RUB
            // Повторный запрос без paymentMethodId,
            // если нет сохраненного способа, просто создаем новый платеж в YooKassa и получаем confirmation_url.
            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                    body: JSON.stringify(requestBody)
                });
                const data = await response.json();
                if (response.ok) {
                    if (data.confirmation && data.confirmation.confirmation_url) {
                        window.location.href = data.confirmation.confirmation_url;
                    } else {
                        window.location.reload();
                    }
                } else {
                    setError(data.message || 'Ошибка при повторной оплате');
                    if (data.confirmation && data.confirmation.confirmation_url) {
                        window.location.href = data.confirmation.confirmation_url;
                    }
                }
            } catch (error: any) {
                console.error(error);
                setError('Ошибка во время повторной оплаты');
            }
        }

        setLoading(false);
    };

    return (
        <>
            <ModalTitle>Оплата</ModalTitle>
            <ModalDescription>
                Пожалуйста, повторите попытку оплаты:
            </ModalDescription>
            {currency === 'usd' && (
                <CardElementWrapper>
                    <CardElement />
                </CardElementWrapper>
            )}
            {error && <ErrorText>{error}</ErrorText>}
            <PurchaseButton onClick={handleSubmit} disabled={loading}>
                {loading ? 'Обработка...' : 'Оплатить'}
            </PurchaseButton>
        </>
    );
};

// Стили для секции привязанных карт
const CardsSection = styled.div`
        margin-top: 20px;
        background-color: #e3f2fd;
        padding: 20px;
        border-radius: 12px;
    `;

const NoCardsText = styled.p`
        font-size: 14px;
        color: #555;
    `;

const CardItem = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        margin-bottom: 10px;
    `;

const CardInfo = styled.div`
        display: flex;
        flex-direction: column;
    `;

const CardBrand = styled.span`
        font-size: 16px;
        font-weight: bold;
        color: #001b4f;
    `;

const CardNumber = styled.span`
        font-size: 14px;
        color: #333;
    `;

const CardExpiry = styled.span`
        font-size: 12px;
        color: #777;
    `;

const DeleteCardButton = styled.button`
        background-color: #d32f2f;
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 24px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #c62828;
        }

        &:focus {
            outline: none;
        }

        &:active {
            background-color: #b71c1c;
        }
    `;

// Остальные стили остаются без изменений

const CourseContainer = styled.div`
        background-color: #f9f9f9;
        padding: 30px;
        border-radius: 16px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    `;

const CourseHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

const CourseTitle = styled.h3`
        color: #001b4f;
        font-size: 24px;
    `;

const PaymentStatus = styled.span<{ paid: boolean }>`
        background-color: ${(props) => (props.paid ? '#4caf50' : '#ffa000')};
        color: white;
        padding: 5px 15px;
        border-radius: 24px;
        font-weight: bold;
    `;

const PaymentInfo = styled.div`
        margin-top: 20px;
        background-color: #fff4e5;
        padding: 20px;
        border-radius: 12px;
        position: relative;
    `;

const PaymentInfoRow = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

const ProgressContainer = styled.div`
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 20px;
    `;

const ProgressBar = styled.div<{ percentage: number }>`
        position: relative;
        height: 10px;
        background-color: #e0e0e0;
        border-radius: 5px;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: ${(props) => props.percentage}%;
            background-color: #ff9900;
            border-radius: 5px;
        }
    `;

const ProgressText = styled.div`
        margin-top: 5px;
        font-size: 14px;
        color: #333;
    `;

const RemainingBalance = styled.div`
        font-size: 16px;
        color: #d32f2f;
        white-space: nowrap;
    `;

const ButtonsContainer = styled.div`
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    `;

const PayButton = styled.button`
        background-color: #ff9900;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    `;

const ErrorText = styled.p`
        color: red;
        margin-top: 10px;
        font-size: 14px;
    `;

const AttemptsContainer = styled.div`
        margin-top: 20px;
        background-color: #fff;
        padding: 15px;
        border-radius: 8px;
    `;

const AttemptsTitle = styled.h4`
        margin-bottom: 10px;
        font-size: 18px;
        color: #001b4f;
    `;

const AttemptItem = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            color: #333;
            margin-right: 10px;
        }
    `;

const SectionsContainer = styled.div`
        margin-top: 30px;
    `;

const ModalOverlay = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

const ModalContent = styled.div`
        background: #001b4f;
        color: #fff;
        padding: 20px;
        border-radius: 24px;
        width: 80%;
        max-width: 600px;
        position: relative;
        max-height: 80vh;
        overflow-y: auto;
    `;

const ModalClose = styled.div`
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        cursor: pointer;
    `;

const ModalTitle = styled.h3`
        margin: 0 0 20px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    `;

const ModalDescription = styled.p`
        font: 400 14px/18px Jost, sans-serif;
        line-height: 1.5;
        text-align: center;
    `;

const PurchaseButton = styled.button`
        width: 100%;
        padding: 10px 20px;
        background-color: #ff9900;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font: 400 16px/20px Jost, sans-serif;

        &:hover {
            background-color: #ff8c00;
        }
        &:disabled {
            background-color: #757575;
            cursor: not-allowed;
        }
        margin-top: 20px;
    `;

const CardElementWrapper = styled.div`
        margin: 20px 0;
        padding: 10px;
        background-color: #ffffff;
        border-radius: 4px;
    `;

const ToggleButton = styled.button`
        background: #ff9900; /* основной фирменный цвет */
        border: none;
        border-radius: 24px;
        padding: 10px 20px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease;
        display: inline-flex;
        align-items: center;
        gap: 8px;

        &:hover {
            background-color: #ff8c00;
        }

        &:focus {
            outline: none;
        }

        &:active {
            background-color: #e67e00;
        }
    `;

const ScheduledPaymentsWrapper = styled.div`
        margin-top: 10px;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    `;

const SectionHeader = styled.h4`
        margin: 0 0 10px;
        font-size: 20px;
        font-weight: 700;
        color: #001b4f;
    `;

const SectionDescription = styled.p`
        font-size: 14px;
        color: #333;
        margin-bottom: 20px;
    `;

const PaymentInfoBlock = styled.div`
        display: flex;
        flex-direction: column;
        gap: 5px;
    `;

const PaymentDate = styled.div`
        font-size: 14px;
        color: #333;
    `;

const PaymentAmount = styled.div`
        font-size: 16px;
        font-weight: 600;
        color: #001b4f;
    `;

const PaymentStatusText = styled.div`
        font-size: 14px;
        font-weight: 500;
    `;

const ScheduledPaymentItem = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #e0e0e0;

        &:last-child {
            border-bottom: none;
        }

        span {
            font-size: 14px;
            color: #333;
        }
    `;

export default CourseItem;
