// src/components/SectionItem.tsx

import React, { useState } from 'react';
import styled from 'styled-components';
import VideoItem from './VideoItem';
import { ISection, ICourse } from '../../contexts/AuthContext';

interface SectionItemProps {
    section: ISection;
    course: ICourse;
}

const SectionItem: React.FC<SectionItemProps> = ({ section, course }) => {
    const [showVideos, setShowVideos] = useState(false);

    const toggleVideos = () => {
        setShowVideos(!showVideos);
    };

    const isAccessible = section.is_available;

    return (
        <SectionContainer>
            <SectionHeader onClick={toggleVideos}>
                <SectionTitle>{section.title}</SectionTitle>
                <ToggleIcon>{showVideos ? '▲' : '▼'}</ToggleIcon>
            </SectionHeader>
            {section.available_from && !section.is_available && (
                <AvailabilityInfo>
                    Секция будет доступна с {new Date(section.available_from).toLocaleDateString()}
                </AvailabilityInfo>
            )}
            {showVideos && (
                <>
                    <SectionDescription>{section.description}</SectionDescription>
                    {!isAccessible && section.unavailable_reason && (
                        <AccessMessage>{section.unavailable_reason}</AccessMessage>
                    )}
                    {isAccessible && (
                        <VideosContainer>
                            {section.videos.map((video) => (
                                <VideoItem key={video.video_id} video={video} />
                            ))}
                        </VideosContainer>
                    )}
                </>
            )}
        </SectionContainer>
    );
};

const SectionContainer = styled.div`
    margin-bottom: 20px;
`;

const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #E3F2FD;
    padding: 15px;
    border-radius: 8px;
`;

const SectionTitle = styled.h4`
    color: #0D47A1;
    font-size: 18px;
`;

const ToggleIcon = styled.span`
    font-size: 18px;
    color: #0D47A1;
`;

const AvailabilityInfo = styled.p`
    color: #FF9900;
    margin-top: 5px;
`;

const SectionDescription = styled.p`
    margin-top: 10px;
    color: #333;
    padding-left: 15px;
`;

const AccessMessage = styled.p`
    margin-top: 10px;
    color: #D32F2F;
    padding-left: 15px;
`;

const VideosContainer = styled.div`
    margin-top: 10px;
    padding-left: 20px;
`;

export default SectionItem;
