import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';

const CourseDetail: React.FC = () => {
    const { courseId } = useParams<{ courseId?: string }>();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    // Check if courseId is defined
    if (!courseId) {
        return <p>Course ID is missing or invalid.</p>;
    }

    const course = currentUser?.courses.find(
        (course) => course.course_id === parseInt(courseId)
    );

    if (!course) {
        return <p>Курс не найден или у вас нет доступа к этому курсу.</p>;
    }

    const handleVideoClick = (videoId: number) => {
        navigate(`/cabinet/video/${videoId}`);
    };

    return (
        <CourseContainer>
            <Header>
                <h1>{course.name}</h1>
                <BackButton onClick={() => navigate('/cabinet')}>Назад</BackButton>
            </Header>
            <Content>
                {course.sections.map((section) => (
                    <Section key={section.section_id}>
                        <h2>{section.title}</h2>
                        <VideoList>
                            {section.videos.map((video) => (
                                <VideoItem key={video.video_id}>
                                    <span>{video.title}</span>
                                    {video.is_available ? (
                                        <WatchButton onClick={() => handleVideoClick(video.video_id)}>
                                            Смотреть
                                        </WatchButton>
                                    ) : (
                                        <span>
                                            Доступно через{' '}
                                            {Math.ceil((video.available_in || 0) / 3600)} часов
                                        </span>
                                    )}
                                </VideoItem>
                            ))}
                        </VideoList>
                    </Section>
                ))}
            </Content>
        </CourseContainer>
    );
};


const CourseContainer = styled.div`
    padding: 20px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BackButton = styled.button`
    background-color: #001B4F;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

const Content = styled.div`
    margin-top: 20px;
`;

const Section = styled.div`
    margin-bottom: 20px;
`;

const VideoList = styled.ul`
    list-style: none;
    padding: 0;
`;

const VideoItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
`;

const WatchButton = styled.button`
    background-color: #004EE4;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

export default CourseDetail;
