import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage: React.FC = () => {
    return (
        <div>
            <Header />
            <TermsContainer>
                <TermsTitle>Пользовательское соглашение</TermsTitle>

                <TermsSection>
                    <SectionTitle>1. Общие положения</SectionTitle>
                    <SectionContent>
                        <p>1.1. Используя услуги Компании, вы выражаете согласие с условиями настоящего Соглашения.</p>
                        <p>1.2. Компания оставляет за собой право вносить изменения в настоящее Соглашение без предварительного уведомления. Новая редакция Соглашения вступает в силу с момента её размещения на Сайте.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>2. Описание услуг</SectionTitle>
                    <SectionContent>
                        <p>2.1. Компания предоставляет доступ к обучающим курсам по теме торговли на платформе Amazon (далее — Курсы).</p>
                        <p>2.2. Доступ к Курсам может быть предоставлен при полной оплате или по рассрочке.</p>
                        <p>2.3. В случае предоставления рассрочки оплата производится поэтапно, с периодичностью и суммами, указанными на Сайте или согласованными с Компанией. После каждой успешно произведенной периодической оплаты Пользователь получает доступ к соответствующей части Курса.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>3. Права и обязанности сторон</SectionTitle>
                    <SectionContent>
                        <p>3.1. Пользователь имеет право:</p>
                        <ul>
                            <li>Получать доступ к соответствующим частям Курсов в соответствии с условиями оплаты и настоящего Соглашения.</li>
                            <li>Получать консультации и поддержку от Компании в пределах объёма, предусмотренного выбранным курсом или программой.</li>
                        </ul>

                        <p>3.2. Пользователь обязуется:</p>
                        <ul>
                            <li>Не распространять полученные материалы Курсов без письменного согласия Компании.</li>
                            <li>Не использовать Курсы в целях, противоречащих законодательству Российской Федерации.</li>
                            <li>Указывать достоверные данные при регистрации и оплате.</li>
                            <li>В случае использования рассрочки своевременно производить предусмотренные платежи. При неудачной попытке автоматического списания произвести оплату вручную в установленный срок.</li>
                            <li>Соблюдать авторские права и иные права интеллектуальной собственности Компании.</li>
                        </ul>

                        <p>3.3. Компания имеет право:</p>
                        <ul>
                            <li>Ограничить доступ к Курсам или отдельным их частям в случае нарушения Пользователем условий настоящего Соглашения.</li>
                            <li>Обновлять содержание Курсов, условия их предоставления и порядок оплат.</li>
                            <li>При наличии двух просроченных платежей по рассрочке или одного просроченного платежа сроком более 2 недель, заблокировать аккаунт Пользователя без возможности восстановления и без возврата ранее уплаченных средств.</li>
                        </ul>

                        <p>3.4. Компания обязуется:</p>
                        <ul>
                            <li>Предоставить доступ к приобретённым Курсам или их частям после получения соответствующих платежей.</li>
                            <li>Обеспечивать консультационную и техническую поддержку Пользователю в рамках условий выбранного Курса.</li>
                        </ul>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>4. Оплата и возврат средств</SectionTitle>
                    <SectionContent>
                        <p>4.1. Оплата за Курсы (полностью или по рассрочке) производится через платёжные системы, указанные на Сайте.</p>
                        <p>4.2. При рассрочке Компания осуществляет автоматические попытки списания указанных сумм с установленной периодичностью. В случае неудачной попытки списания Пользователь будет уведомлён о необходимости произвести оплату вручную.</p>
                        <p>4.3. При наличии двух просроченных платежей или одного просроченного платежа, просроченного более чем на 2 недели, Компания вправе заблокировать доступ к Курсам без возможности восстановления. В данном случае уплаченные ранее средства не возвращаются.</p>
                        <p>4.4. В случае невозможности предоставления Курсов по вине Компании, Пользователь имеет право на пропорциональный возврат средств.</p>
                        <p>4.5. Возврат средств не производится, если Пользователь получил доступ к Курсам (либо их части при рассрочке) и начал обучение.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>5. Конфиденциальность</SectionTitle>
                    <SectionContent>
                        <p>5.1. Компания обязуется не передавать личные данные Пользователя третьим лицам без его согласия, за исключением случаев, предусмотренных законодательством Российской Федерации.</p>
                        <p>5.2. Компания принимает меры для защиты личных данных Пользователя в соответствии с Политикой конфиденциальности.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>6. Ответственность</SectionTitle>
                    <SectionContent>
                        <p>6.1. Компания не несёт ответственности за невозможность использования Курсов по причинам, не зависящим от неё.</p>
                        <p>6.2. Компания не несёт ответственности за убытки, понесённые Пользователем в результате использования Курсов, если такие убытки не вызваны умышленными действиями Компании.</p>
                        <p>6.3. Пользователь несёт ответственность за соблюдение законодательства при применении полученных знаний и навыков.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>7. Заключительные положения</SectionTitle>
                    <SectionContent>
                        <p>7.1. Все споры, возникающие из настоящего Соглашения, подлежат разрешению в соответствии с законодательством Российской Федерации.</p>
                        <p>7.2. В случае признания какого-либо положения Соглашения недействительным, остальные положения остаются в силе.</p>
                    </SectionContent>
                </TermsSection>

                <TermsSection>
                    <SectionTitle>Дата последнего обновления</SectionTitle>
                    <SectionContent>
                        <p>12 Декабря 2024 года</p>
                    </SectionContent>
                </TermsSection>
            </TermsContainer>
            <Footer />
        </div>
    );
};

const TermsContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  background-color: #001B4F;
  color: #fff;
  font-family: 'Inter', sans-serif;
`;

const TermsTitle = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TermsSection = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #ddd;
`;

export default TermsPage;
