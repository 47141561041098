// src/pages/SetPassword.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const SetPassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tokenValid, setTokenValid] = useState<boolean | null>(null);
    const [firstName, setFirstName] = useState('');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.get(`https://amazonfreedom.ru/api/verify_token/${token}`);
                setTokenValid(true);
                setFirstName(response.data.first_name);  // Сохраняем имя пользователя
            } catch (err) {
                setTokenValid(false);
                setError('Неверный или истекший токен. Если проблема сохраняется, свяжитесь с поддержкой в Telegram: @amazonfreedom');
            }
        };
        verifyToken();
    }, [token]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (password !== confirmPassword) {
            setError('Пароли не совпадают. Если проблема сохраняется, свяжитесь с поддержкой в Telegram: @amazonfreedom');
            return;
        }

        if (password.length < 6) {  // Пример минимальной длины пароля
            setError('Пароль должен содержать минимум 6 символов. Если проблема сохраняется, свяжитесь с поддержкой в Telegram: @amazonfreedom');
            return;
        }

        try {
            const response = await axios.post(`https://amazonfreedom.ru/api/set_password/${token}`, { password });
            setSuccessMessage(response.data.message || 'Пароль успешно установлен! Перенаправление на страницу входа...');
            setTimeout(() => {
                navigate('/cabinet/login');
            }, 2000);
        } catch (err: any) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(`${err.response.data.message}. Если проблема сохраняется, свяжитесь с поддержкой в Telegram: @amazonfreedom`);
            } else {
                setError('Произошла ошибка. Попробуйте позже или свяжитесь с поддержкой в Telegram: @amazonfreedom');
            }
        }
    };

    if (tokenValid === null) {
        return <MessageContainer>Проверка токена...</MessageContainer>;
    }

    if (tokenValid === false) {
        return <MessageContainer>{error}</MessageContainer>;
    }

    return (
        <SetPasswordContainer>
            <SetPasswordForm onSubmit={handleSubmit}>
                <Title>Здравствуйте, {firstName}!</Title>
                <Subtitle>Пожалуйста, установите пароль для вашего аккаунта.</Subtitle>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                <Input
                    type="password"
                    placeholder="Новый пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Input
                    type="password"
                    placeholder="Подтвердите пароль"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <SubmitButton type="submit">Установить пароль</SubmitButton>
            </SetPasswordForm>
        </SetPasswordContainer>
    );
};

const SetPasswordContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f4f4f4;
`;

const SetPasswordForm = styled.form`
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px;
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 10px;
    color: #001B4F;
`;

const Subtitle = styled.p`
    text-align: center;
    margin-bottom: 20px;
    color: #333;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0 16px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
`;

const SubmitButton = styled.button`
    width: 100%;
    background-color: #001B4F;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #004EE4;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    margin-bottom: 10px;
`;

const SuccessMessage = styled.div`
    color: green;
    text-align: center;
    margin-bottom: 10px;
`;

const MessageContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
    color: ${props => props.color || '#333'};
`;

export default SetPassword;
