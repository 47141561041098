// src/pages/Login.tsx

import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
    const { setCurrentUser } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch('https://amazonfreedom.ru/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Сохраняем токен в localStorage
                localStorage.setItem('access_token', data.access_token);

                // Вызовите функцию для обновления текущего пользователя
                await fetchCurrentUser();

                // Перенаправляем пользователя
                navigate('/cabinet');
            } else {
                setError(data.message || 'Ошибка входа');
            }
        } catch (err) {
            setError('Ошибка соединения с сервером');
        }
    };

    // Функция для получения текущего пользователя
    const { fetchCurrentUser } = useAuth();

    return (
        <LoginContainer>
            <LoginForm onSubmit={handleSubmit}>
                <Title>Вход</Title>
                {error && (
                    <ErrorMessage>
                        {error}
                        <SupportMessage>
                            Если у вас возникли проблемы с авторизацией, напишите в поддержку: <a href="https://t.me/amazonfreedom">@amazonfreedom</a>
                        </SupportMessage>
                    </ErrorMessage>
                )}
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Input
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <SubmitButton type="submit">Войти</SubmitButton>
            </LoginForm>
        </LoginContainer>
    );
};

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f4f4f4;
`;

const LoginForm = styled.form`
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: #001B4F;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0 16px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
`;

const SubmitButton = styled.button`
    width: 100%;
    background-color: #001B4F;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #004EE4;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    margin-bottom: 10px;
`;

const SupportMessage = styled.p`
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    a {
        color: #004EE4;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
`;

export default Login;
