import React from 'react';
import styled from 'styled-components';
import { IVideo } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

interface VideoItemProps {
    video: IVideo;
}

const VideoItem: React.FC<VideoItemProps> = ({ video }) => {
    const navigate = useNavigate();

    const handleVideoClick = () => {
        if (video.is_available) {
            navigate(`/cabinet/video/${video.video_id}`);
        }
    };

    return (
        <VideoContainer onClick={handleVideoClick} disabled={!video.is_available}>
            <VideoTitle>{video.title}</VideoTitle>
            {video.is_available ? (
                <StatusBadge available>Доступно</StatusBadge>
            ) : (
                <>
                    <StatusBadge>Недоступно</StatusBadge>
                    {video.available_from && (
                        <AvailabilityInfo>
                            Доступно с {new Date(video.available_from).toLocaleDateString()}
                        </AvailabilityInfo>
                    )}
                    {video.unavailable_reason && (
                        <AccessMessage>{video.unavailable_reason}</AccessMessage>
                    )}
                </>
            )}
        </VideoContainer>
    );
};

const VideoContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    margin-bottom: 15px;
    padding: 10px;
    background-color: #F1F8E9;
    border-radius: 8px;
`;

const VideoTitle = styled.span`
    font-size: 16px;
    color: #2E7D32;
`;

const StatusBadge = styled.span<{ available?: boolean }>`
    padding: 3px 7px;
    background-color: ${(props) => (props.available ? '#4CAF50' : '#F44336')};
    color: white;
    border-radius: 12px;
    font-size: 12px;
    margin-top: 5px;
    align-self: flex-start;
`;

const AvailabilityInfo = styled.span`
    color: #FF9900;
    margin-top: 5px;
    font-size: 14px;
`;

const AccessMessage = styled.p`
    color: #D32F2F;
    margin-top: 5px;
    font-size: 14px;
`;

export default VideoItem;
